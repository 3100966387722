<template>
  <div id="ib_report">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="title">{{ $t('menu.ibReport') }}</div>
        <div class="query">
          <div class="flex seach_box">
            <div class="flex query-container flo">
              <AccountNumber @accountCallback="queryIbReportData"></AccountNumber>
              <SelectTree :accountID="accountID" :chooseNode.sync="chooseNode" />
            </div>
            <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
            <div class="flex flex-center seach_btn" :class="{ btn_update_small: lang === 'km' }">
              <loading-button
                class="ib-button"
                :callback="queryIbReportData"
                text="common.keys.UPDATE"
              ></loading-button>
              <loading-button class="ib-button" :callback="downLoad" text="common.keys.DOWNLOAD"></loading-button>
            </div>
          </div>
          <div class="theme_box">
            <el-row :gutter="10">
              <el-col
                :sm="12"
                data-testid="netFunding"
                class="cursor-pointer"
                :class="{ active: selectedCard == 'netFunding' }"
                @click="selectedCard = 'netFunding'"
              >
                <BlockView
                  :title="$t('home.NET_FUNDING')"
                  :val="cardData.netFunding.value | currency('')"
                  :icon="require('@/assets/uploads/netfunding.png')"
                  :currAccountCurrencyFilter="currAccountCurrencyFilter"
                  :changeStatus="() => (selectedCard = 'netFunding')"
                  :class="{ active: selectedCard == 'netFunding' }"
                />
              </el-col>
              <el-col :sm="12">
                <BlockView
                  data-testid="deposits"
                  :title="$t('home.DEPOSITS')"
                  :val="cardData.deposits.value | currency('')"
                  :icon="require('@/assets/uploads/deposts.png')"
                  :currAccountCurrencyFilter="currAccountCurrencyFilter"
                  :changeStatus="() => (selectedCard = 'deposits')"
                  :class="{ active: selectedCard == 'deposits' }"
                />
              </el-col>
              <el-col :sm="12">
                <BlockView
                  :title="$t('home.WITHDRAW')"
                  :val="cardData.withdraw.value | currency('', 0)"
                  :icon="require('@/assets/uploads/widthdraw.png')"
                  :currAccountCurrencyFilter="currAccountCurrencyFilter"
                  :changeStatus="() => (selectedCard = 'withdraw')"
                  :class="{ active: selectedCard == 'withdraw' }"
                />
              </el-col>
              <el-col :sm="12">
                <BlockView
                  :title="$t('ibReport.openedAccs')"
                  :val="cardData.openedAccounts.value | currency('', 0)"
                  :icon="require('@/assets/uploads/trade.png')"
                  :changeStatus="() => (selectedCard = 'openedAccounts')"
                  :class="{ active: selectedCard == 'openedAccounts' }"
                />
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="content clearfix">
          <!-- 1. net Funding 2.deposits 3.withdraw -->
          <div v-if="['netFunding', 'deposits', 'withdraw'].indexOf(selectedCard) >= 0">
            <div class="item_header">
              {{ $t('ibReport.funding') }} {{ $t('common.keys.FROM') }} {{ startDate | formatDate('DD/MM/YYYY') }}
              {{ $t('common.keys.TO') }} {{ endDate | formatDate('DD/MM/YYYY') }}
            </div>
            <div class="table_box">
              <el-table :data="fundingDisplayData" style="width: 100%">
                <el-table-column :label="$t('common.keys.DATE')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.date | formatDate('DD/MM/YYYY') }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('ibReport.account')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.mt4Account }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('home.DEPOSITS')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.deposit | currency('') }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('home.WITHDRAW')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.withdraw | currency('') }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('home.NET_FUNDING')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ currAccountCurrencyFilter }}{{ scope.row.net | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <pagination v-bind:table-data="fundingTableData" v-bind:display-data.sync="fundingDisplayData"></pagination>
          </div>
          <!-- 1. opened Accounts -->
          <div v-if="selectedCard == 'openedAccounts'">
            <div class="item_header">
              {{ $t('ibReport.openedAccs') }} {{ $t('common.keys.FROM') }} {{ startDate | formatDate('DD/MM/YYYY') }}
              {{ $t('common.keys.TO') }} {{ endDate | formatDate('DD/MM/YYYY') }}
            </div>
            <div class="table_box">
              <el-table :data="openedAccountsDisplayData" style="width: 100%" @sort-change="sortChange">
                <el-table-column :label="$t('common.keys.DATE')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.approvedDate | date('DD/MM/YYYY') }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.keys.ACCNUM')" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.mt4Account }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.keys.NAME')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.keys.EMAIL')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.email }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('common.keys.ACCTYPE')"
                  sortable
                  sort-by="type"
                  min-width="110"
                  :sort-orders="['ascending', 'descending']"
                >
                  <template slot-scope="scope">
                    <span> {{ $platform.accountTypeMaps(regulator)[scope.row.type] }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="platform"
                  :label="$t('common.keys.PLATFORM')"
                  sortable
                  sort-by="platform"
                  min-width="120"
                  :sort-orders="['ascending', 'descending']"
                ></el-table-column>
                <el-table-column
                  prop="currency"
                  :label="$t('common.keys.BASECURRENCY')"
                  sortable
                  sort-by="currency"
                  min-width="130"
                  :sort-orders="['ascending', 'descending']"
                ></el-table-column>
                <el-table-column
                  :label="$t('common.keys.BALANCE')"
                  sortable
                  sort-by="balance"
                  min-width="110"
                  :sort-orders="['ascending', 'descending']"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.currency | currencySymbol }}{{ scope.row.balance | currency('') }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <pagination
              v-bind:table-data="openedAccountsTableData"
              v-bind:display-data.sync="openedAccountsDisplayData"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import LoadingButton from '@/components/LoadingButton';
import AccountNumber from '@/components/form/AccountNumber';
import SelectTree from '@/components/SelectTree';
import { apiQueryIbReportData } from '@/resource';
import moment from 'moment';
import { Message } from 'element-ui';
import ExportJsonExcel from 'js-export-excel';
import BlockView from '@/components/BlockView';
export default {
  components: { Pagination, DateRangePicker, LoadingButton, AccountNumber, BlockView, SelectTree },
  data() {
    return {
      userID: this.$store.state.common.CUID,
      startDate: '',
      endDate: '',
      //可能存在修改时间，却没有点刷新的情况，所以要记录一下刷新时间。
      updateTimeRecord: { startDate: this.startDate, endDate: this.endDate },
      cardData: {
        netFunding: { label: 'NET FUNDING', value: 0 },
        deposits: { label: 'DEPOSITS', value: 0 },
        withdraw: { label: 'WITHDRAW', value: 0 },
        openedAccounts: { label: 'OPENED ACCOUNTS', value: 0 }
      },
      selectedCard: '',
      fundingTableData: [],
      fundingDisplayData: [],
      openedAccountsTableData: [],
      openedAccountsDisplayData: [],
      currAccountCurrencyFilter: '',
      chooseNode: null
    };
  },
  methods: {
    downLoad() {
      //没有数据是下载功能return
      if (!this.fundingTableData.length) {
        Message({
          message: this.$t('report.nodataMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }
      // 间隔天数
      let days = moment(this.endDate).diff(moment(this.startDate), 'days');
      // 允许下载最大天数
      const MAX_DAYS = 90;
      const fileName = `ib_report_${moment(this.updateTimeRecord.startDate).format('YYYY-MM-DD')}_${moment(
        this.updateTimeRecord.endDate
      ).format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD HH:mm:ss A')}`;
      if (days > MAX_DAYS) {
        Message({
          message: this.$t('report.timeLimitMessage'),
          type: 'warning'
        });
        return Promise.resolve();
      }

      const fundReportHeader = [
        'common.keys.DATE',
        'home.Account',
        'home.DEPOSITS',
        'home.WITHDRAW',
        'home.NET_FUNDING'
      ].map(key => this.$t(key));

      const openedAccountHeader = [
        'common.keys.DATE',
        'home.Account',
        'home.Name',
        'common.keys.EMAIL',
        'common.keys.ACCTYPE',
        'common.keys.PLATFORM',
        'common.keys.BASECURRENCY',
        'home.availableBalance'
      ].map(key => this.$t(key));
      //配置表格信息
      let option = {
        fileName,
        datas: [
          {
            sheetData: this.fundingTableData,
            sheetName: 'fund report',
            sheetHeader: fundReportHeader,
            columnWidths: [5, 5, 6, 6, 7]
          },
          {
            sheetData: this.parseExcleData(this.openedAccountsTableData),
            sheetName: 'opened accounts',
            sheetHeader: openedAccountHeader,
            columnWidths: [5, 6, 7, 8, 8, 5, 6, 5]
          }
        ]
      };
      const toExcel = new ExportJsonExcel(option);
      toExcel.saveExcel();
      return Promise.resolve();
    },
    queryIbReportData() {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }
      this.updateTimeRecord = {
        startDate: this.startDate,
        endDate: this.endDate
      };
      return apiQueryIbReportData({
        userId: this.userID,
        rebateAccount: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        subRebateAccount: this.chooseNode?.mt4Account || ''
      }).then(resp => {
        const respData = resp.data;
        console.log('%c 输出结果', 'color：red', respData);
        this.cardData.netFunding.value = respData.netFunding;
        this.cardData.deposits.value = respData.totalDeposit;
        this.cardData.withdraw.value = respData.totalWithdraw;
        this.cardData.openedAccounts.value = respData.openedAccounts;

        this.fundingTableData = respData.funding;
        this.openedAccountsTableData = respData.openedAccountsDetail;

        this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'balance';
      this.sorting.order = this.sorting.order || 'descending';
      this.openedAccountsTableData.sort((a1, a2) => {
        return this.sorting.order == 'descending'
          ? a2[this.sorting.column] - a1[this.sorting.column]
          : a1[this.sorting.column] - a2[this.sorting.column];
      });
    },
    //转换opened accounts 导出excle要求格式
    parseExcleData(openedAccountsTableData) {
      return openedAccountsTableData.map(item => {
        const { approvedDate, name, platform, mt4Account, currency, balance, type, email } = item;
        return {
          approvedDate: moment(approvedDate).format('YYYY-MM-DD'),
          mt4Account,
          name,
          email,
          type: this.$platform.accountTypeMaps(this.regulator)[type],
          platform,
          currency,
          balance
        };
      });
    },
    sortChange(column, prop, order) {
      if (!column.column) {
        return;
      }
      this.sorting = { column: column.column.sortBy, order: column.order };
      this.sortData();
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.selectedCard = query.cardMethod || 'netFunding';
      },
      immediate: true
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    regulator() {
      return this.$store.state.common.regulator;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/ibReport.scss';
</style>
